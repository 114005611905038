import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import Header from '../Header';
import axios from 'axios';
import Loader from '../Loader/Loader';
import { Container, Row, Col, Card, Image } from 'react-bootstrap';
import Episodes from './Episodes';
import AudioPlayer from './AudioPlayer';
const AudioFileDetail = () => {
    const { id } = useParams();
    const [audiodetail, setAudioData] = useState([]);
    const [episodes, setEpisodeData] = useState([]);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        getAudioFileDetails()
    }, [])
    const getAudioFileDetails = async () => {
        try {
            setLoading(true);
            const data = JSON.stringify({
                'user_id': localStorage.getItem('dastaan__user_id') !== null ? localStorage.getItem('dastaan__user_id') : 1,
                audio_file_id: id
            });
            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_API_URL}/audio-file-detail`,
                headers: {
                    'Authorization': localStorage.getItem('dastaan__token'),
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                data: data
            };
            axios.request(config).then((response) => {
                setLoading(false);
                const resData = response.data.Podcast_App;
                const audio_file_detail = resData.audio_file_data;
                setAudioData(audio_file_detail)
                setEpisodeData(resData.episodes)
            })
        } catch (error) {
            setLoading(false);
        }
    }
    const backgroundImageStyle = audiodetail.landscape_thumb ? `${audiodetail.landscape_thumb.replace(' ', '%')}` : 'none'
    /*     let link = null;
        if (localStorage.getItem('user_id') !== undefined)
            link = `/watch/${id}`;
        else
            link = `/login` */
    const hour = (parseFloat(audiodetail.total_duration) / 3600).toFixed(2)
    const hrArr = hour.split('.');
    const min = (hrArr[1]);
    const seconds = parseInt(min / 60);
    // const secArr = seconds.split('.')
    return (
        // 
        <>
            {
                loading ?
                    (<Loader loading={loading} />) :
                    (
                        <>
                            <Header />
                            <Container className='mt-7 audio-detail-main'>
                                <Row >
                                    <Col md={6} sm={12}>
                                        <Row>
                                            <Col md={12} sm={12}>
                                                <Image className=''
                                                    src={backgroundImageStyle}
                                                    style={{
                                                        width: '100%',
                                                        objectFit: 'cover',
                                                        borderRadius:'5px',
                                                        // backgroundPosition: 'left',
                                                        height: '30vh',
                                                    }}
                                                />
                                            </Col>
                                            <Col md={12} sm={12}>
                                                <h3 className='movie-detail-tile'><b>{audiodetail.title}</b></h3>
                                                <ul>
                                                    <Row>
                                                        <Col sm={4} md={3} xs={4}>
                                                            <li>
                                                                <small>{`${episodes.length} Episodes`} </small>
                                                            </li>
                                                        </Col>
                                                        <Col sm={6} md={3} xs={6} className='text-left'>
                                                            <li>
                                                                <small>{hrArr[0]}:{min}:{seconds > 0 ? seconds : '00'}</small>
                                                            </li>
                                                        </Col>
                                                    </Row>
                                                </ul>
                                                <p className='audio-detail-description' style={{textAlign:'justify'}}>
                                                    {audiodetail.description}
                                                </p>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col md={6} sm={12}>
                                        <Episodes episodes={episodes} thumb={backgroundImageStyle} />
                                    </Col>
                                </Row>
                            </Container>
                        </>
                    )
            }
            <footer className="app-footer">
                <AudioPlayer />
            </footer>
        </>
    );
}
export default AudioFileDetail