import React from 'react'
import Slider from 'react-slick/lib/slider';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
const CategoryWiseFiles = ({ audiofiles, categories }) => {
    console.log(audiofiles);
    const CustomNextArrow = ({ onClick }) => (
        <button className="custom-next-arrow" onClick={onClick}>
            <FontAwesomeIcon aria-hidden="true" icon={faAngleRight} />
        </button>
    );
    const CustomPrevArrow = ({ onClick }) => (
        <button className="custom-prev-arrow" onClick={onClick}>
            <FontAwesomeIcon aria-hidden="true" icon={faAngleLeft} />
        </button>
    );
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 8,
        slidesToScroll: 1,
        nextArrow: <CustomNextArrow />,
        prevArrow: <CustomPrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    initialSlide: 2,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            }
        ]
    };
    // console.log(audiofiles, categories);
    return (
        <div className="slider-container">
            <>
                {categories.map((category, index) => {
                    return (<div key={category.category_id}>
                        <h3 className="home-heading">{audiofiles[index].length > 0 ? category.category_name : ''}</h3>
                        {
                            audiofiles[index].length > 0 ? (
                                <Slider {...settings}>
                                    {audiofiles[index].map((audFile) => {
                                        const hr = (audFile.duration / 3600).toFixed(2);
                                        const hrArr = hr.split('.');
                                        const min = (hrArr[1] / 60).toFixed(2);
                                        const minArr = min.split('.');
                                        const sec = parseInt(minArr[1]);
                                        return (
                                            <>
                                                <Link to={`/audio-file-detail/${audFile.audio_file_id}`}>
                                                    <div className="card-wrapper">
                                                        <div className="card">
                                                            <div className='image'>
                                                                <img src={audFile.landscape_thumb} alt={audFile.title} className="card-img-top slick-slider-img" />
                                                            </div>
                                                            <div className="details ">
                                                                <div className="center">
                                                                    <Row>
                                                                        <Col md={12} sm={12} xs={12}>
                                                                            <span className='file-title text-white'>{audFile.title}</span>
                                                                        </Col>
                                                                        <Col md={6} sm={6} xs={12}>
                                                                            <span className='text-white' style={{
                                                                                border: '1px solid gray',
                                                                                padding: '3px 8px'
                                                                            }}>{audFile.language_name}</span>
                                                                        </Col>
                                                                        <Col md={6} sm={6} xs={12}>
                                                                            <span className='text-white text-left'
                                                                                style={{
                                                                                    border: '1px solid gray',
                                                                                    padding: '3px 8px'
                                                                                }}
                                                                            >{`${audFile.access_mode == 1 ? 'Paid' : 'Free'}`}</span>
                                                                        </Col>
                                                                        <Col md={12} sm={12} xs={12}>
                                                                            <span className='text-white'>{audFile.author}</span>
                                                                        </Col>
                                                                        <Col md={6} sm={6} xs={12}>
                                                                            <span className='text-white'>{`${parseInt(hr)}h ${parseInt(min)}m ${sec}s`}</span>
                                                                        </Col>
                                                                    </Row>
                                                                    {/*  <h1>{audFile.title}</h1>
                                                            <h1>{audFile.title}</h1> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </>
                                        )
                                    })}
                                </Slider>
                            ) : ''
                        }
                    </div>
                    )
                })
                }
            </>
        </div>
    )
}
export default CategoryWiseFiles