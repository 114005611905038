import React, { useEffect, useState } from 'react'
import Slider from '../Slider'
import Header from '../Header'
import axios from 'axios'
import Loader from '../Loader/Loader'
import CategoryWiseFiles from '../AudioFiles/CategoryWiseFiles'
const Home = () => {
  const [sliders, setSlider] = useState([])
  const [loading, setLoading] = useState(true);
  const [categories, setCatgories] = useState([]);
  const [audiofiles, setAudioFiles] = useState([]);
  useEffect(() => {
    getHomePageData()
  }, [])
  const getHomePageData = () => {
    setLoading(true)
    const data = JSON.stringify({
      'user_id' : localStorage.getItem('dastaan__user_id') !=  null ? localStorage.getItem('dastaan__user_id') : 1 
    });
   
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/home`,
      headers: { 
        'Authorization': localStorage.getItem('dastaan__token'), 
        'Accept': 'application/json', 
        'Content-Type': 'application/json'
      },
      data : data
    };
    axios.request(config).then((response) => {
      setLoading(false)
      // console.log(response.data);
      setCatgories(response.data.Podcast_App.categories)
      // setRecentlyListened(response.data.Podcast_App.recently_watched)
      setAudioFiles(response.data.Podcast_App.audio_file_data)
      setSlider(response.data.Podcast_App.audio_file_data[2])
    })
  }
  return (
    <>
      {loading ?
        (
          <Loader loading={loading} />) : (
          <>
            <Header />
            <Slider sliders={sliders} />
            <CategoryWiseFiles audiofiles={audiofiles}  categories={categories}/>
          
          </>
        )}
    </>
  )
}
export default Home