import React, { useState, useEffect, useRef } from 'react'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Navbar, Container, NavDropdown, Nav,Image } from 'react-bootstrap'
import { Link } from 'react-router-dom';

const Header = () => {
  const logo = useRef();
  // const [activeMenu, setActiveMenu] = useState(null);
  const [isScrolled, setIsScrolled] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      setIsScrolled(offset > 50);
      if(offset > 50)
      logo.current.src = `${process.env.PUBLIC_URL}/logo/Dastaan_logo.png`
      else
       logo.current.src = `${process.env.PUBLIC_URL}/logo/Dastaan_logo.png`

    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const user_id = localStorage.getItem('dastaan__user_id');
  // setUserPlansStatus(user_plan);
  let menu;
  if (user_id == null) {
    menu = <Link className={`nav-link mr-auto p-2`} to="/login">
      <Nav className='nav-link'>Login</Nav>
    </Link>
  } else {
    const userLogOut = () => {
      // console.log('logout function', user_plan);
      localStorage.removeItem('dastaan__user_image')
      localStorage.removeItem('dastaan__name')
      localStorage.removeItem('dastaan__subscription_id')
      localStorage.removeItem('dastaan__user_id')
      localStorage.removeItem('dastaan__user_plan_status')
      localStorage.removeItem('dastaan__plan_active')
      localStorage.removeItem('dastaan__is_parent_user')
      localStorage.removeItem('dastaan__phone')
      window.location.reload();
    }
    menu = <><span className='user-profile-name'>{localStorage.getItem('dastaan__name')}</span>
    <NavDropdown className={`mr-auto text-white ml-1`} title={
    <Image 
    src={localStorage.getItem('user_image') !== '' 
      ? localStorage.getItem('user_image') : 'https://cdn.dribbble.com/users/1123508/screenshots/4602728/gif-final.gif'} 
    className="user-profile-image" roundedCircle />} 
    id="basic-nav-dropdown">
      <Link to="/plans" className='dropdown-item'>Profile</Link>
      <Link to="/plans" className='dropdown-item'>Plan</Link>
      <Link to="/plans" className='dropdown-item'>Transactions</Link>
      <Link to="/my-watchlist" className='dropdown-item'>My Watchlist</Link>
      <NavDropdown.Divider />
      <NavDropdown.Item onClick={userLogOut}>
        Logout
      </NavDropdown.Item>
      
    </NavDropdown>
    </>
  }
  return (
    <>
      <Navbar expand="lg" className={isScrolled ? 'navbar-scrolled' : 'navbar-transparent'} bg="dark" data-bs-theme="dark"
        fixed='top'>
        <Container>
          <Link to="/" className='p-2'>
            <Nav>
              {/* <span className='logo'>{process.env.PUBLIC_URL}/logo/Dastaan_logo.png</span> */}
              <Image ref={logo} src={`${process.env.PUBLIC_URL}/logo/Dastaan_logo.png`} className='logo' alt="logo" title="Dastaan" />
            </Nav>
          </Link>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
            </Nav>
            {menu}
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <ToastContainer />
    </>
  )
}
export default Header