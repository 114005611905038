import React from 'react'
import DotLoader from "react-spinners/DotLoader";

const Loader = ({loading}) => {
   
    return (
        <div className="loader">
            <DotLoader color="#FF9156" />
        </div>
    )

}
export default Loader